import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logo from '../../img/vapyr_analytics_main_logo.png';
import { removeAlert } from '../../actions/alert';

const Landing = ({ isAuthenticated }) => {
    useEffect(() => {
        removeAlert()
        window.scrollTo(0, 0)
      }, []);
    
    
    return (
      <Fragment>
        <section className="container"> 
        

        <section className="landing">
        <div id="page-wrapper" className = "header-background">
          <div id="logo" className= "container">
              <img  src={ logo } alt="#" />

            </div>

        <section id="banner">
          <header>
          <h2><em> VAPYR ANALYTICS is a data analytics company focused on novel data-driven solutions. </em></h2>
            <Link to="/about-us#top" className="button">Learn More</Link>
          </header>
        </section>

        <section className="wrapper style1">
          <div className="container">
          <div className="row gtr-200">
            {/* <section className="col-4 col-12-narrower">
              <div className="box highlight">
                <h3>Follow Vapyr Analytic News</h3>
                <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p>
              </div>
            </section> */}
            <section className="col-6 col-12-narrower">
              <div className="box highlight">
                {/* <i className="icon solid major fa-pencil-alt"></i> */}
                  {
                    !isAuthenticated ? (<h3><Link to="scoreboardform"> Vapyr Timeline Demo </Link></h3>) :
                    (<h3><Link to="userboard"> Vapyr Timeline And Method App </Link></h3>)

                  }
                {/* <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p> */}
              </div>
            </section>
            <section className="col-6 col-12-narrower">
              <div className="box highlight">
                {/* <i className="icon solid major fa-wrench"></i> */}
                <h3><Link to="contact-us"> Contact Us </Link></h3>
                {/* <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p> */}
              </div>
            </section>
          </div>
          </div>
        </section>

      <section className="wrapper style2">
        <div className="container">
          <header className="major">
            {/* <h2>A gigantic heading you can use for whatever</h2> */}
            {/* <p>With a much smaller subtitle hanging out just below it</p> */}
          </header>
        </div>
      </section>

      {/* <section className="wrapper style1">
        <div className="container">
          <div className="row">
            {/* <section className="col-6 col-12-narrower">
              <div className="box post">
                <Link to="#" className="image left"><img src={pic01} alt="" /></Link>
                <div className="inner">
                  <h3>The First Thing</h3> 
                  <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p>
                </div>
              </div>
            </section>
            <section className="col-6 col-12-narrower">
              <div className="box post">
                <Link to="#" className="image left"><img src={pic02} alt="" /></Link>
                <div className="inner">
                  {/* <h3>The Second Thing</h3>
                  <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p> 
                </div>
              </div>
            </section>
          </div>
          <div className="row">
            <section className="col-6 col-12-narrower">
              <div className="box post">
                <Link to="#" className="image left"><img src={pic03} alt="" /></Link>
                <div className="inner">
                  {/* <h3>The Third Thing</h3>
                  <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p> 
                </div>
              </div>
            </section>
            <section className="col-6 col-12-narrower">
              <div className="box post">
                <Link to="#" className="image left"><img src={pic04} alt="" /></Link>
                <div className="inner">
                  {/* <h3>The Fourth Thing</h3>
                  <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p> 
                </div>
              </div>
            </section> 
          </div>
        </div>
      </section> 

      <section id="cta" className="wrapper style3">
        <div className="container">
          <header>
            <h2>Are you ready to continue your quest?</h2>
            <Link to="#" className="button">Insert Coin</Link> 
          </header>
        </div>
      </section> 
            */}


    </div>


        </section>
</section>  
      </Fragment>

    );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Landing);
