import React, {Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layouts/Spinner';
import Facility from './Facility';
import { clearMethod } from '../../actions/method';

import { removeAlert } from '../../actions/alert';

const MethodBoard = ({ score: { watersystem: {water_system, facility} }}) => {
    const [show, setShow] = useState(false);
    const handleClick = (e) => {
        removeAlert();
        e.preventDefault();
        setShow(true);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <Fragment> 
            <div className = "container">
                
            { !water_system ? (<Spinner/>) :( 
                <section className="wrapper style1">
                    <h3>Water System - { water_system.water_system_name }</h3>
                        <div id="watersystemcard">
                            <ul>
                                <li className="water_system_card_content">{ water_system.water_system_number }</li>
                                <li className="water_system_card_content">{ water_system.water_system_name }</li>
                            </ul>
                        </div>
                        <h2> Contaminant Scores for the System </h2>
                        <div id="contam_score_cards">   
                            { !facility ? (<Spinner/>) :
                                ( facility.map(
                                    (fac, idx) => (
                                    <div key={idx} className = "button contam" >
                                        <Facility onClick={e => handleClick(e)} key={idx} facility={fac} watersystem={water_system} />
                                    </div>
                                        )
                                    )
                                )
                            };
                        </div>
                </section>
                )};
            </div>         
        </Fragment> 
    );
};

MethodBoard.propTypes = {
    score: PropTypes.object, 
    clearMethod: PropTypes.func
};

const mapStateToProps = state => ({
    score: state.score
});

export default connect(mapStateToProps, { clearMethod })(MethodBoard);