import {
    GET_ALL_TIMELINE,
    GET_ALL_TRIAL_TIMELINE,
    CLEAR_TIMELINE,
    TIMELINE_ERROR,
    TRIAL_TIMELINE_ERROR
} from '../actions/types'

const initialState = {
    timeline: null,
    loading: true,
    error: {} 

}

function timelineReducer(state = initialState, action) {
    const { type, payload } = action;
    switch(type) {
        case GET_ALL_TIMELINE:
        case GET_ALL_TRIAL_TIMELINE:
            return {
                ...state,
                timeline: payload,
                loading: false
            };
        case TIMELINE_ERROR:
        case TRIAL_TIMELINE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
                
            };
        case CLEAR_TIMELINE: 
            return {
                timeline: null,
                loading: true,
                error: {}
            };
        default:
            return state;
    };
};

export default timelineReducer;