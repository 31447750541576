import React, {  useState } from 'react';
import { connect } from 'react-redux';
import Spinner from '../layouts/Spinner';
import qm from '../../img/question-mark.png';
import compliance_legend from '../../img/compliance_legend.PNG';
import { timeline_dropdown_text  } from './timelineLib';
import TableauReport from 'tableau-react';

const options =  {
    // height: 100,
    // width: 100,
    hideTabs: false,
    // added interval support - an integer can be passed as milliseconds in the options object and refreshDataAsync() will refresh the data automatically on your preferred interval.
    // All other vizCreate options are supported here, too
    // They are listed here: https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record
  };
  
  const filters = {
    Colors: ['Blue', 'Red'],
    Sizes: ['Small', 'Medium']
  };
  

  function capitalizeFirstWord(str) {
    // Split the string into an array by "_"
    let words = str.split('_');
    
    // Check if there's at least one word
    if (words.length > 0) {
      // Get the first word
      let firstWord = words[0];
      
      // Capitalize the first letter of the first word and add the rest of the word
      return firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
    }
    
    return ''; // Return an empty string if the original string was empty
  }

const TimelineModule = (props) => {
 
    const { timeline_arr, timeline_url, contaminant, facility_name, contam_id, fac_id } = props;
    // const timeline_value_array = Object.keys(timeline);
    const [timelineArrIdx, setTimelineArrIdx] = useState(timeline_arr[0]);
    const parameters = {
        contam_id_param: contam_id,
        fac_id_param: fac_id,
        Table: capitalizeFirstWord(timelineArrIdx)
      };
    const onSelectionChange = (e) => {
        e.preventDefault();
        setTimelineArrIdx(e.target.value);
    
    };


    return (
        <div>    
            <h3 id="timeline-title"> Timeline for { contaminant } Compliance in Facility { facility_name } </h3>
            
                <TableauReport
                url= { timeline_url }
                // filters={filters}
                parameters={parameters}
                options={options} // vizCreate options
                // Overwrite default query params
                // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
                query="?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes"
                />

                <div>
                    <p> 
                        Timeline Type:  
                        <select name="current_timeline" onChange={ e => onSelectionChange(e)} >
                            <option value={timeline_arr[0]}> {timeline_arr[0] + " (Scoring)"} </option>
                            <option value={timeline_arr[1]}> {timeline_arr[1]} </option>
                            <option value={timeline_arr[2]}> {timeline_arr[2]} </option>
                        </select>
                    </p>
                </div>    
        </div>

    );
        
    


    
}

// const mapStateToProps = state => ({
//     timeline: state.timeline, 
//     potentialrepair: state.potentialrepair
// })


export default connect(null)(TimelineModule);
