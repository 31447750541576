const leap_or_not = {
    true: 366,
    false: 365
};

export function dateStringToExcelDate(date_string){
    let date_string_arr = date_string.split("-");
    let year_to_int = parseInt(date_string_arr[0]);
    let month_to_int = parseInt(date_string_arr[1]) - 1;
    let date_to_int = parseInt(date_string_arr[2]);
    let new_date = new Date(year_to_int, month_to_int, date_to_int, 0, 0, 0, 0);
    var days = Math.round((new_date - new Date(1899, 11, 30)) / 8.64e7);
    days.toString();
    return days;
}

export function returnPercentage(a, b) {
    return Math.round((a/b) * 10000) / 100;
};



export function ExcelDateToJSDate(excelDate) {
    const full_date = new Date(((excelDate - 25568) * 86400 * 1000));
    let year = String(full_date.getFullYear());
    let month = String(full_date.getMonth() + 1);
    let date = String(full_date.getDate());
    let full_date_string = year + "-" + month + "-" + date;
    return full_date_string;
}

export const timeline_color = {
    "GREEN": "green",
    "YELLOW": "#ffcc00",
    "RED": "red",
    "BLACK": "black",
    "TBD": "gray"
};

export const timeline_dropdown_text = {
    "effective_timeline": "Effective Timeline",
    "cycle_timeline": "Cycle Timeline",
    "period_timeline": "Period Timeline",
};

export const standby_determination =  {
    "effective_timeline": "effective_standby",
    "cycle_timeline": "cycle_standby",
    "period_timeline": "period_standby"
};


export const timelineConstructSpan = (timeline) => {
    console.log(timeline)
    console.log("SDSFSDF")
    let timeline_annual_points = [];
    let start_point = timeline[0].start_date;

    let total_end_date = timeline[timeline.length - 1].end_date;
    let span = total_end_date - start_point;
    let start_date_str = ExcelDateToJSDate(timeline[0].start_date);
    let end_date_str = ExcelDateToJSDate(timeline[timeline.length - 1].end_date);
    
    let start_year = parseInt(start_date_str.slice(0, 4));
    let end_year = parseInt(end_date_str.slice(0, 4));
    let start_year_end_point = dateStringToExcelDate(`${start_year}-12-31`);
    let end_year_end_point = dateStringToExcelDate(`${end_year - 1}-12-31`);
    
    let loop_start_point = 0;
    let loop_end_point = start_year_end_point - start_point + 1
    let year_range = end_year - start_year;

    timeline_annual_points.push([start_date_str, returnPercentage(loop_start_point, span).toString() + "%", returnPercentage(loop_end_point, span).toString() + "%"]);
    for (let i = 1; i < year_range; i++){
        let insert_year = "";
        if (year_range < 20){
            insert_year = start_year + i;
        }
        else if (i % 5 === 0){
            insert_year = start_year + i;
        };
        loop_start_point = loop_end_point + 1;
        loop_end_point = loop_end_point + leap_or_not[(insert_year % 4) === 0] - 1
        timeline_annual_points.push([insert_year, returnPercentage(loop_start_point, span).toString() + "%", returnPercentage(loop_end_point, span).toString() + "%"]);
    };
    loop_start_point = loop_end_point + 1;
    loop_end_point = loop_end_point + (total_end_date - end_year_end_point);
    if (year_range > 20 && end_year % 5 !== 0){
        end_year = "";
    };
    timeline_annual_points.push([end_year,  returnPercentage(loop_start_point, span).toString() + "%", returnPercentage(loop_end_point, span).toString() + "%"]);
    timeline_annual_points.push([end_date_str, "100%", "100%"]);
    return timeline_annual_points;
}


export const timelineDefine = (timeline) => {
    let timeline_diff = [];
    let timeline_proportion = [];
    let start_point = parseInt(timeline[0].start_date);
    let total_end_date = parseInt(timeline[timeline.length - 1].end_date);
    let span = total_end_date - start_point;
    let start_date = 0;
    let end_date = 0;
    for (let i = 0; i < timeline.length; i++){
        start_date = parseInt(timeline[i].start_date);
        end_date = parseInt(timeline[i].end_date);
        timeline_diff.push([start_date - start_point, end_date - start_point]);
    }
    let pointA = "";
    let pointB = "";
    
    for (let j = 0; j < timeline_diff.length; j++) {
        let local_time_avg = ((timeline_diff[j][1] + timeline_diff[j][0]) / 2) 
        let shape = "line";
        let ltdToPer = returnPercentage(local_time_avg, span);
        let shouldTriangle = returnPercentage(timeline_diff[j][1] - timeline_diff[j][0], span);
        if(shouldTriangle < 1){
            pointA = ltdToPer;
            shape = "point";
        }
        else {
            pointA = returnPercentage(timeline_diff[j][0], span);
        }
        pointB =  returnPercentage(timeline_diff[j][1], span);
        timeline_proportion.push([pointA, pointB, timeline[j].color, shape]);
    }

    return timeline_proportion;        
}

export const repairDefine = (repair, real_start_date, real_end_date) => {
    if ((Array.isArray(repair) && repair.length === 0)) {
        return [];
    }
    let repair_diff = [];
    let repair_proportion = [];
    let start_point = real_start_date;
    let total_end_date = real_end_date;
    let span = total_end_date - start_point;
    let start_date = 0;
    let end_date = 0;
    let repair_color = "";
    let delay_start = 0;
    for (let i = 0; i < repair.length; i++){
        start_date = parseInt(repair[i].start_date);
        end_date = parseInt(repair[i].end_date);
        if ((end_date - start_point) > 0){
            let convert_to_date_start = "";
            let convert_to_date_end = "";
            if (delay_start === 0){
                convert_to_date_start = ExcelDateToJSDate(start_date);
            }
            else {
                convert_to_date_start = ExcelDateToJSDate(start_point);
            };
            
            convert_to_date_end = ExcelDateToJSDate(end_date);
            if (repair[i].potential_standby_determination === "Seems to be mechanically operational for this time range"){
                repair_color = "#dbe9f4"; // azureish white
            }
            else {
                repair_color = "black";
            };
            repair_diff.push([start_date - start_point, end_date - start_point, repair_color, convert_to_date_start, convert_to_date_end]);
        }
    }

    let pointA = "";
    let pointB = "";
    repair_diff[0][0] = 0;
    repair_diff[repair_diff.length - 1][1] = total_end_date - real_start_date;
    for (let j = 0; j < repair_diff.length; j++) {
        let local_time_avg = ((repair_diff[j][1] + repair_diff[j][0]) / 2);
        let shape = "line";
        let ltdToPer = returnPercentage(local_time_avg, span);
        let shouldTriangle = returnPercentage(repair_diff[j][1] - repair_diff[j][0], span);
        if(shouldTriangle < 1){
            pointA = ltdToPer;
            shape = "point";
        }
        else {
            pointA = returnPercentage(repair_diff[j][0], span);
        };
        pointB =  returnPercentage(repair_diff[j][1], span);
        

        repair_proportion.push([pointA, pointB, repair_diff[j][2], shape, repair_diff[j][3], repair_diff[j][4]]);
    };
    return repair_proportion;        
};

export const timelineArrow = timeline => {
    let timeline_diff = [];
    let timeline_arrow = [];
    let start_point = parseInt(timeline[0].start_date);
    let total_end_date = parseInt(timeline[timeline.length - 1].end_date);
    let span = total_end_date - start_point;
    let start_date = 0;
    let end_date = 0;
    for (let i = 0; i < timeline.length; i++){
        start_date = parseInt(timeline[i].start_date);
        end_date = parseInt(timeline[i].end_date);
        timeline_diff.push([start_date - start_point, end_date - start_point]);
    };
    for (let j = 0; j < timeline_diff.length; j++) {
        let arrow_axis = ((timeline_diff[j][0] + timeline_diff[j][1]) / 2);
        let arrow_axis_percentage = returnPercentage(arrow_axis, span);
        timeline_arrow.push([arrow_axis_percentage]);
    };
    return timeline_arrow;        
};



export const timelineClass = (idx, timelineArrIdx) => {
    return "timeline-chart-" + timelineArrIdx + "-" + idx;
}
