import {
    GET_ALL_WATERSYSTEM,
    GET_ALL_ASSOCIATED_FACILITY, 
    GET_ALL_WATERSYSTEM_CONTAM_SCORE, 
    GET_WATERSYSTEM_AND_FAC,
    CLEAR_FACILITY,
    CLEAR_WATERSYSTEM,
    GET_ALL_WATERSYSTEM_CONTAM_SCORE_ERROR,
    GET_ALL_ASSOCIATED_FACILITY_ERROR,
    GET_WATERSYSTEM_AND_FAC_ERROR
} from '../actions/types'

const initialState = {
    watersystem: [],
    facility: [],
    loading: true,
    fac_loading: true,
    error: {} 
}

function scoreReducer(state = initialState, action) {
    const { type, payload } = action;
    switch(type) {
        case GET_ALL_WATERSYSTEM:
            return {
                ...state,
                watersystem: payload,
                loading: false
            };
        case GET_ALL_WATERSYSTEM_CONTAM_SCORE:
            return {
                ...state,
                watersystem: payload,
                loading: false
            };
        case GET_ALL_ASSOCIATED_FACILITY:
            return {
                ...state,
                facility: payload,
                loading: false,
                fac_loading: false
            };
        case GET_WATERSYSTEM_AND_FAC:
            return {
                ...state,
                watersystem: payload,
                loading: false,
                fac_loading: false
            };
        case CLEAR_WATERSYSTEM:
            return {
                ...state,
                watersystem: [],
                loading: true,
                error: {} 
            };
        case CLEAR_FACILITY:
            return {
                ...state,
                facility: [],
                fac_loading: true,
                error: {}
            };
        case GET_ALL_WATERSYSTEM_CONTAM_SCORE_ERROR:
        case GET_ALL_ASSOCIATED_FACILITY_ERROR:
        case GET_WATERSYSTEM_AND_FAC_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };    
        default:
            return state;
    };
};

export default scoreReducer;