import React, { Fragment, useEffect  } from 'react';
import { Link  } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { removeAlert } from '../../actions/alert';
import { clearProfile } from '../../actions/profile';



const Footer = ({ auth: { isAuthenticated, loading } }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch() 
    const authLinks = (
      <ul className="links">
        <li>
          <Link to="/">
            HOME
          </Link>
        </li>
        <li>
          <Link to="/about-us">
            ABOUT US
            </Link>
        </li>
        <li>
          <Link to="/userboard">
            User Dashboard
          </Link>
        </li>
        <li>
          <Link to="/contact-us">
            CONTACT US
          </Link>
        </li>
      </ul>
    );
    
    const guestLinks = (
      <ul className="links">
        <li><Link to="/">
            HOME
          </Link></li>
        <li><Link to="/about-us">ABOUT US</Link></li>
        <li><Link to="/scoreboardform">SAMPLING DEMO</Link></li>
        <li><Link to="/contact-us">CONTACT US</Link></li>
      </ul>
    );
    return (
        <Fragment>
          <div className="container ">
            <div className="row">
              <section className="col-3 col-6-narrower col-12-mobilep">
                {/* <h3>Website</h3>
                  {
                    isAuthenticated ? (authLinks) : (guestLinks)
                  } */}
              </section>

				<section className="col-3 col-6-narrower col-12-mobilep">
                	{/* <h3>Reference</h3>
                	<ul className="links">
                  		<a href="https://sdwis.waterboards.ca.gov/PDWW/index.jsp">SDWIS</a>
                	</ul> */}
              </section>
          
            </div>
            <div className="copyright">
              <ul className="menu footer-credits">
                <li>&copy; Vapyr Analytics. All rights reserved since 2021</li>
                <li>&copy; Adapted Arcana Design from: <Link to="http://html5up.net">HTML5 UP</Link></li>
                <li>&copy; Question Mark from Flat Icon License: <Link to="https://www.flaticon.com/free-icons/question-mark" title="question mark icons">Question mark icons created by Vector Stall - Flaticon</Link></li>
              </ul>
            </div>
          </div>
      
          {/* <ul className="icons">
            <li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
            <li><a href="#" className="icon brands fa-github"><span className="label">GitHub</span></a></li>
            <li><a href="#" className="icon brands fa-linkedin-in"><span className="label">LinkedIn</span></a></li>
            <li><a href="#" className="icon brands fa-google-plus-g"><span className="label">Google+</span></a></li>
          </ul> */}
    
         
    
      </Fragment>
      
    );

};

Footer.propTypes = {
  auth: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(mapStateToProps)(Footer);