import React, {Fragment, useEffect} from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layouts/Spinner';
import Timeline from './Timeline.js';
import {  getAllAssociatedFacility } from '../../actions/score';
import { removeAlert } from '../../actions/alert';


const FacilityScoreBoard = ()  => {
    

    const {facility, loading, watersystem, fac_loading} = useSelector(state => state.score)
    const {isAuthenticated} = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const { ws_id, contam_id } = useParams()
    useEffect(() => {
        removeAlert()

        Promise.resolve(dispatch(getAllAssociatedFacility(ws_id, contam_id, isAuthenticated)))
        window.scrollTo(0, 0)

    }, [isAuthenticated, dispatch, ws_id, contam_id])


    
    return (
        <Fragment> 
            <div className = "container">
                { ( !facility && !watersystem && loading && fac_loading ) ? (<Spinner />) :( 
                    <section className="wrapper style1">
                        <h2> { facility.contam_name } for { watersystem.water_system.water_system_name }</h2>
                            <div id="watersystemcard">
                                {
                                    (facility && !fac_loading) ? (                                
                                        <ul>
                                            <li className="water_system_card_content"> {facility.contam_name} Score for { watersystem.water_system.water_system_name }</li>
                                            <li className="water_system_card_content"> Percentile Average:  { facility.water_system_contam.red_lean_percentile }</li>
                                        </ul>
                                    ) : (<Spinner />)
                                }
                            </div>
                            <h2> Contaminant Scores in Each Facility </h2>
                            <div id="contam_score_cards" >
                            { fac_loading ? (<Spinner />) : 
                                ( facility.new_fac_contam.map((facility_contamination, idx) => (

                                        <Timeline key={idx} watersystem={watersystem} facility={facility_contamination} facility_contam={facility_contamination} conv_score={facility_contamination.red_lean_score}/>
                                    )))
                            }
                            </div>
                    </section>
                )}
            </div>
        </Fragment> 
    
    )
                        }

FacilityScoreBoard.propTypes = {
    score: PropTypes.object,
    getAllAssociatedFacility: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    score: state.score,
    buttonTimeline: state.buttonTimeline,
})

export default connect(mapStateToProps, {getAllAssociatedFacility})(FacilityScoreBoard);
