import React, {Fragment, useState} from 'react';
import { connect,  useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getMethodHistory, getMethodPriority, clearMethod } from '../../actions/method';
import MethodPriorityModule from './MethodPriorityModule';
import { clearWaterSystem, clearFacility } from '../../actions/score';
import { clearTimeline } from '../../actions/timeline';

const Facility = props => {

    const {  facility, method, watersystem } = props;
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const handleClick = (e) => {
        e.preventDefault();  
        setShow(true);
        Promise.resolve(dispatch(getMethodPriority(facility.id))).then(
                () => dispatch(getMethodHistory(facility.id)));
    };

    const handleClose = () => {
        dispatch(clearMethod());
        setShow(false);
    };

    return (
        <Fragment>
            { show  ? (
                <div className="timeline">

                    <div className="timeline-inner" style={{
                            maxHeight: 'calc(100vh - 210px)',
                            overflowY: 'auto'}}>
                        <h3  id="timeline-title"> Method Table for { facility.facility_name } </h3>

                        <MethodPriorityModule method_priority = {method.method_priority} method_history={method.method_history}  />
                        <button className="close-btn" onClick={handleClose}>Close Method Priority</button>
                    </div>
                </div>) : null}

            <div>
                <p>{ watersystem.water_system_number } - {facility.state_asgn_id_number}</p>
                <p>{ facility.facility_name }</p>
                <button onClick={handleClick}>Show Method Priority Table</button>

            </div>
            {/* <p> Yellow Lean Percentile Average: { yellow_lean_percentile_average }</p> */}
        </Fragment>
    );
};

Facility.propTypes = {
    score: PropTypes.object,
    getMethodHistory: PropTypes.func.isRequired,
    getMethodPriority: PropTypes.func.isRequired,
    clearMethod: PropTypes.func
};

const mapStateToProps = state => ({
    score: state.score,
    method: state.method, 
});


export default connect(mapStateToProps, { getMethodHistory, getMethodPriority, clearMethod})(Facility);