import api from '../utils/api';
import { setAlert } from './alert';
import {
    GET_METHOD_PRIORITY,
    GET_METHOD_HISTORY,
    CLEAR_METHOD,
    METHOD_PRIORITY_ERROR,
    METHOD_HISTORY_ERROR
} from './types';




export const getMethodPriority = (fac_id) => async dispatch => {
    try {
        const res = await api.get(`/methodpriority/facility/${fac_id}`);
        dispatch({
            type:GET_METHOD_PRIORITY,
            payload: res.data
        }, 6000);

    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((err) => dispatch(setAlert(err.msg, 'danger')));
        }
        dispatch({
            type: METHOD_PRIORITY_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        }, 6000)
    }
}

export const getMethodHistory = (fac_id) => async dispatch => {
    try {
        const res = await api.get(`/methodhistory/facility/${fac_id}`);
        dispatch({
            type: GET_METHOD_HISTORY,
            payload: res.data
        }, 6000);

    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((err) => dispatch(setAlert(err.msg, 'danger')));
        }
        dispatch({
            type: METHOD_HISTORY_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        }, 6000)
    }
}

export const clearMethod = () => ({ type: CLEAR_METHOD });
