export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';


// DEORECATED
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT  = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';

// Vapyr App Actions
export const GET_PERIOD_TIMELINE = 'GET_PERIOD_TIMELINE';
export const GET_CYCLE_TIMELINE = 'GET_CYCLE_TIMELINE';
export const GET_EFFECTIVE_TIMELINE = 'GET_EFFECTIVE_TIMELINE';
export const GET_ALL_TIMELINE = "GET_ALL_TIMELINE";
export const CLEAR_TIMELINE = 'CLEAR_TIMELINE';
export const GET_TIMELINE_ERROR = 'GET_TIMELINE_ERROR';
export const GET_ALL_WATERSYSTEM = 'GET_ALL_WATERSYSTEM';
export const GET_ALL_STORET = 'GET_ALL_STORET';
export const GET_STORET = 'GET_STORET';
export const GET_WATERSYSTEM_SCORE = 'GET_WATERSYSTEM_SCORE';
export const GET_WATERSYSTEM_SCORE_BY_WATER_SYSTEM_NUMBER = 'GET_WATERSYSTEM_SCORE_BY_WATER_SYSTEM_NUMBER';
export const CLEAR_WATERSYSTEM = 'CLEAR_WATERSYSTEM';
export const GET_FACILITY_BY_CONTAM = 'GET_FACILITY_BY_CONTAM';
export const GET_ALL_ASSOCIATED_FACILITY = 'GET_ALL_ASSOCIATED_FACILITY';
export const CLEAR_FACILITY = 'CLEAR_FACILITY';
export const GET_ALL_ASSOCIATED_FACILITY_SCORE = 'GET_ALL_ASSOCIATED_FACILITY_SCORE';
export const GET_ALL_WATERSYSTEM_CONTAM_SCORE = 'GET_ALL_WATERSYSTEM_CONTAM_SCORE';
export const GET_ALL_FACILITY_CONTAM_SCORE = 'GET_ALL_FACILITY_CONTAM_SCORE';
export const GET_POTENTIAL_REPAIR = "GET_POTENTIAL_REPAIR"
export const GET_DEBUG = "GET_DEBUG";
export const GET_METHOD_PRIORITY = "GET_METHOD_PRIORITY";
export const GET_METHOD_HISTORY = "GET_METHOD_HISTORY";
export const CLEAR_POTENTIAL_REPAIR = "CLEAR_POTENTIAL_REPAIR";
export const CLEAR_METHOD = "CLEAR_METHOD";
export const GET_ASSOCIATED_FACILITY = "GET_ASSOCIATED_FACILITY";
export const GET_WATERSYSTEM_AND_FAC = "GET_WATERSYSTEM_AND_FAC";
export const GET_POTENTIAL_REPAIR_TRIAL = "GET_POTENTIAL_REPAIR_TRIAL";
export const GET_ALL_TRIAL_TIMELINE = "GET_ALL_TRIAL_TIMELINE";

export const WATER_SYSTEM_ERROR = "WATER_SYSTEM_ERROR";
export const FACILITY_ERROR = "FACILITY_ERROR";
export const TIMELINE_ERROR = "TIMELINE_ERROR";
export const TRIAL_TIMELINE_ERROR = "TRIAL_TIMELINE_ERROR";
export const METHOD_PRIORITY_ERROR = "METHOD_PRIORITY_ERROR";
export const METHOD_HISTORY_ERROR = "METHOD_HISTORY_ERROR";


export const REPAIR_ERROR = "REPAIR_ERROR";
export const TRIAL_REPAIR_ERROR = "TRIAL_REPAIR_ERROR"; 
export const GET_ALL_WATERSYSTEM_CONTAM_SCORE_ERROR = "GET_ALL_WATERSYSTEM_CONTAM_SCORE_ERROR";
export const GET_WATERSYSTEM_AND_FAC_ERROR = "GET_WATERSYSTEM_AND_FAC_ERROR";
export const GET_ALL_ASSOCIATED_FACILITY_ERROR = "GET_ALL_ASSOCIATED_FACILITY_ERROR";