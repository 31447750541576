import React, { Fragment, useEffect, useState, useRef  } from 'react';
import { connect } from 'react-redux';

// import ReCaptchaV2 from 'react-google-recaptcha';
import { removeAlert } from '../../actions/alert';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import emailjs from '@emailjs/browser';



const ContactUs = (props) => {
    const form = useRef();



    const [fromName, setFromName] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [organizationEmail, setOrganizationEmail] = useState('');
    const [organizationPhonenumber, setPhonenumber] = useState('');
    const [messageVar, setMessage] = useState('');
    const [captchaString, setCaptchaString] = useState('');

    useEffect(() => {
        removeAlert()
        window.scrollTo(0, 0)
        loadCaptchaEnginge(6); 
      }, [])
    // const handleFormReset = () => {
    //     setState(() => initialState)
    //     loadCaptchaEnginge(6); 
    // }
    const onSubmit = e => {
        e.preventDefault();

        let user_captcha_value = document.getElementById('user_captcha_input').value;
      
        
        if (validateCaptcha(user_captcha_value)===true) {
            emailjs.sendForm("service_o5ndh2c","template_c46983p", form.current, 'hdbF81V1wVrT84x10')
            .then((result) => {
                setFromName('');
                setOrganizationName('');
                setOrganizationEmail('');
                setPhonenumber('');
                setMessage('');
                setCaptchaString('');
                loadCaptchaEnginge(6);
                alert("Your message has been sent.")

            }, (error) => {
                loadCaptchaEnginge(6);

            });
        }
   
        else {
            alert('Captcha Does Not Match');
        }

    };
    return (
        <Fragment>
            <section className="container">
                <div id="header" className= "wrapper style-1">

                        <h1>Contact Us</h1>
                        {/* <p> This page will be updated. </p> */}
{/* 
                        Don't SPAM Us Bro
                        Add CAPTCHA Robot Catcher */}
                        <form ref={form} onSubmit={e => onSubmit(e)} >
                            <div className="row gtr-5">
                                <div className="col-6 col-12-mobilep">
                                    <input type="text" name="from_name" id="name" placeholder="Name" onChange={event => setFromName(event.target.value)} value={fromName} required/>
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <input type="text" name="organization_name" id="name" placeholder="Organization Name" onChange={event => setOrganizationName(event.target.value)} value={organizationName} required/>
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <input type="email" name="organization_email" id="email" placeholder="Email"  onChange={event => setOrganizationEmail(event.target.value)} value={organizationEmail} required/>
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <input type="text" name="phonenumber" id="phonenumber" placeholder="Business Phone Number (Optional)"  onChange={event => setPhonenumber(event.target.value)} value={ organizationPhonenumber } />
                                </div>
                                <div className="col-12">
                                    <textarea name="message" id="message" placeholder="Type in your message." rows="5" onChange={event => setMessage(event.target.value)}  value={messageVar}></textarea>
                                </div>
                                <div className="col-12">
                                    <LoadCanvasTemplate reloadText="Reload My Captcha" />
                                    <input type="text" name="cuser_captcha_input" id="user_captcha_input" placeholder="Type in CAPTCHA" onChange={event => setCaptchaString(event.target.value)}  value={captchaString} required/>
                                </div>
                                <div className="col-12">
                                    <ul className="actions">
                                        <li><input type="submit" className="button alt" value="Send Message" /></li>
                                    </ul>

                                </div>
                                
                            </div>
                        </form>

                </div>
            </section> 
        </Fragment>
    
            
    );
}

ContactUs.propTypes = {

}

export default connect(null)(ContactUs);
