import api from '../utils/api';
import { setAlert } from './alert';
import {
    GET_ALL_TIMELINE,
    GET_ALL_TRIAL_TIMELINE,
    CLEAR_TIMELINE,
    TIMELINE_ERROR,
    TRIAL_TIMELINE_ERROR
} from './types';




export const getAllTimeline = (fac_id, contam_id, conv_score) => async dispatch => {

    try {
        const res = await api.get(`/timeline/facility/${fac_id}/contam/${contam_id}/${conv_score}`);
        dispatch({
            type: GET_ALL_TIMELINE,
            payload: res.data
        }, 500000);        
    } catch (err) {
        if (err.response) {
            // Request made and server responded
            dispatch({
                type: TIMELINE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
            });
            dispatch(setAlert('Could Not Load Timeines. If problem persists contact webmaster.'));
        } else if (err.request) {
            dispatch({
                type: TIMELINE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
            });
            dispatch(setAlert('Request Error for some reason.'));
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
        }
        
    }
};

export const getAllTrialTimeline = (fac_id, contam_id, conv_score) => async dispatch => {

    try {
        const res = await api.get(`/timeline/facility/${fac_id}/contam/${contam_id}/${conv_score}/trial`);
        dispatch({
            type: GET_ALL_TRIAL_TIMELINE,
            payload: res.data
        }, 500000);        
    } catch (err) {
        if (err.response) {
            // Request made and server responded
            dispatch({
                type:  TRIAL_TIMELINE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
            });
            dispatch(setAlert('Could Not Load Timeines. If problem persists contact webmaster.'));
        } else if (err.request) {
            dispatch({
                type:  TRIAL_TIMELINE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
            });
            dispatch(setAlert('Request Error for some reason.'));
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
        }
        
    }
};


export const clearTimeline = () => ({ type: CLEAR_TIMELINE });
