import {
    GET_METHOD_PRIORITY,
    GET_METHOD_HISTORY, 
    CLEAR_METHOD,
    METHOD_PRIORITY_ERROR,
    METHOD_HISTORY_ERROR
} from '../actions/types';

const initialState = {
    method_history: [],
    method_priority: [],
    loading: true,
    error: {} 
};

function scoreReducer(state = initialState, action) {
    const { type, payload } = action;
    switch(type) {
        case GET_METHOD_PRIORITY:
            return {
                ...state,
                method_priority: payload,
                loading: false
            };
        case GET_METHOD_HISTORY:
            return {
                ...state,
                method_history: payload,
                loading: false
            };
        case CLEAR_METHOD:
            return {
                ...state,
                method_history: [],
                method_priority: [],
                loading: true,
                error: {} 
            };
        case METHOD_PRIORITY_ERROR:
        case METHOD_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
                
            };
        default:
            return state;
    };
};

export default scoreReducer;