
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layouts/Navbar';
import Landing from './components/layouts/Landing';
import Alert from './components/layouts/Alert';
import AboutUs from './components/layouts/AboutUs';
import ContactUs from './components/layouts/ContactUs';
import ScoreBoardForm from './components/timeline/ScoreBoardForm';
import WaterSystemScoreBoard from './components/timeline/WaterSystemScoreBoard';
import FacilityScoreBoard from './components/timeline/FacilityScoreBoard';
import Footer from './components/layouts/Footer';
import WaterSystemFacility from './components/timeline/WaterSystemFacility';
import MethodBoard from './components/timeline/MethodBoard';
import NotFound from './components/layouts/NotFound';
import Login from './components/auth/Login';
import PrivateRoute from './components/routing/PrivateRoute';
import UserBoard from './components/utilityUser/UserBoard';

import { LOGOUT } from './actions/types';

import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';
import { loadState } from './localStorage';
import './main.css';


const App = () =>  { 
  useEffect(() => {
    // check for token in LS when app first runs
    let state = loadState()
    
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

    return( 
        <Provider store={store}>
              <Router> 
                  <Navbar />
                  <Alert />
                  {/* <Footer /> */}
                  <Routes>
                    <Route path="/" element= {<Landing />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/scoreboardform" element={<ScoreBoardForm />} />
                    <Route path="/watersystemscore" element={<WaterSystemScoreBoard />} />
                    <Route path="/facilitysystemscore/:ws_id/:contam_id" element={<FacilityScoreBoard />} />
                    <Route path="/watersystemfacility" element={<WaterSystemFacility />} />
                    <Route path="/methodboard" element={<MethodBoard />} />
                    <Route path="/scoreboardform/:water_system_number" element={<ScoreBoardForm />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/userboard" element={<PrivateRoute component={UserBoard}/>} />
                    <Route path="/*" element={<NotFound />} />
                  </Routes>
                  <Footer />

              </Router>
        </Provider>
    
  )

};


export default App;
