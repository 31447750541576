import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layouts/Spinner';

const MethodPriorityModule = (props) => {

    const {  method_priority, method_history } = props;

    return (
        <div> 
            { (method_priority.length > 0 && method_history.length > 0) ? (   
                <div > 
                    <table>
                            <tr className="timeline-chart-head" >
                                <th> Method </th>
                                <th> Color </th>
                                <th> Requirement </th>
                                <th> Determination </th>
                                <th> Overage </th>

                            </tr>
                            {  
                                method_priority.map((method_unit, idx) => (
                                <tr key={idx}  >
                                    <td> { method_unit.method } </td>
                                    <td> {method_unit.color} </td>
                                    <td> { method_unit.requirement } </td>
                                    <td> { method_unit.determination } </td>
                                    <td> { method_history[idx].adj_overage } </td>
                                </tr>
                                    
                                ))
                            }
                    </table>
                </div>
            ) : (
                    (<Spinner />) 
                ) 
            };
        </div> );




    
}

MethodPriorityModule.propTypes = {
    method: PropTypes.array
};

const mapStateToProps = state => ({
    method: state.method
});

export default connect(null,  mapStateToProps)(MethodPriorityModule);
