import React,  {Fragment, useEffect } from 'react'
import Spinner from '../layouts/Spinner';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { clearProfile, getCurrentProfile} from '../../actions/profile';
import { clearFacility, clearWaterSystem, getLoggedInAssociatedFacility, getLoggedInWaterSystemByWSNum } from '../../actions/score';
import { clearTimeline } from '../../actions/timeline';
import PropTypes from 'prop-types';

const UserBoard= ({
    getCurrentProfile,
    getLoggedInAssociatedFacility,
    getLoggedInWaterSystemByWSNum,
    clearWaterSystem,
    clearFacility,
    clearTimeline,
    auth: { user, isAuthenticated },
    profile: { profile }, 
  }) => {
    const navigate = useNavigate();
    const dispatch  = useDispatch();
    useEffect(() => {
        if (isAuthenticated){
  
            Promise.resolve(getCurrentProfile()).then(
                () => dispatch(clearWaterSystem)).then(
                    () => dispatch(clearFacility)).then(
                        () => dispatch(clearTimeline))
        }

    }, [getCurrentProfile, isAuthenticated]);

    const onTimelineClick = (e, wsnumber) => {
        e.preventDefault();
        Promise.resolve(getLoggedInWaterSystemByWSNum(wsnumber));
        navigate('/watersystemscore');
    };

    const onMethodClick = (e, wsnumber) => {
        e.preventDefault();
        Promise.resolve(getLoggedInAssociatedFacility(wsnumber))
        navigate('/methodboard')
    };

    return  (
        <Fragment> 
            <section className="container"> 
                <div id="header" className= "wrapper style-1">
                {
                    user ? ( <h1> Login Confirmed for {user.user_name} </h1>) : 
                    (<Spinner />)
                }
                {
                    profile ? (
                        <>
                            <h1> {profile.ddw_name} </h1>
                            <table>
                                <tr>
                                    <th> Water System Name</th> 
                                    <th> Water System Number</th>
                                    <th> Regulatory Agency / Office </th>
                                    <th> </th>
                                    <th> </th>
                                </tr>
                                
                                {
                                    ( profile.map((file, idx) => (
                                    <tr key={idx}>
                                        <td> {file.water_system_name} </td>
                                        <td> {file.water_system_number} </td>
                                        <td> {file.ddw_name}</td>
                                        <td>  
                                            <p onClick={(e) => {
                                            onTimelineClick(e, file.water_system_number)
                                        }} className = "timelineButton"> Timelines for Contaminants </p>
                                        </td>
                                        <td >    
                                            <p onClick={(e) => {
                                            onMethodClick(e, file.water_system_number)
                                        }} className = "methodButton"> Methods </p>
                                        </td>
                                    </tr>
                                    )))
                                };
                            </table>
                            
                            {/* <a onClick={logout} href="#!">
                                <i className="fas fa-sign-out-alt" />{' '}
                                <span className="hide-sm">Logout</span>
                            </a>          */}
                        </>
                    ) :
                    (<Spinner />)
                };


                </div>
                
            </section>
                    
        </Fragment> 
    );
};

UserBoard.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    getLoggedInAssociatedFacility: PropTypes.func.isRequired, 
    getLoggedInWaterSystemByWSNum: PropTypes.func.isRequired,
    clearFacility: PropTypes.func,
    clearWaterSystem: PropTypes.func,
    clearTimeline: PropTypes.func,
    clearProfile: PropTypes.func,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile
});

export default connect(mapStateToProps, {getCurrentProfile, getLoggedInAssociatedFacility, getLoggedInWaterSystemByWSNum, clearFacility, clearTimeline, clearWaterSystem, clearProfile})(UserBoard);