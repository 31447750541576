import React, { Fragment, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { useNavigate } from 'react-router';
import { removeAlert } from '../../actions/alert';
import { clearProfile } from '../../actions/profile';

const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch() 

    const onLogout = () => {
      Promise.resolve(dispatch(logout())).then(
            () => navigate("login")).then(
              () => dispatch(clearProfile()))
    };
    useEffect(() => {
      removeAlert()
    }, [])
    const authLinks = (
    <ul>
      <li><Link to="/">
        Vapyr Analytics
      </Link></li>
      <li><Link to="/about-us">ABOUT US</Link></li>
      {/* <li><Link to="/scoreboardform">SAMPLING DEMO</Link></li> */}
      <li>
        <Link to="/userboard">
          User Dashboard
        </Link>
      </li>
      <li><Link to="/contact-us">CONTACT US</Link></li>
      <li>
        <a onClick={onLogout} href="#!">
          <span className="hide-sm">LOGOUT</span>
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li><Link to="/">
          Vapyr Analytics
        </Link></li>
      <li><Link to="/about-us">ABOUT US</Link></li>
      <li><Link to="/scoreboardform">SAMPLING DEMO</Link></li>
      <li><Link to="/contact-us">CONTACT US</Link></li>
      <li>
        <Link to="/login">LOGIN</Link>
      </li>
    </ul>
  );

  return (
    <nav id="nav">
      <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
    </nav>
  );
    
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Navbar);
