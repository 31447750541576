import React, {  useState } from 'react';
import { connect } from 'react-redux';
import Spinner from '../layouts/Spinner';
import qm from '../../img/question-mark.png';
import compliance_legend from '../../img/compliance_legend.PNG';
import { ExcelDateToJSDate, timeline_color, repairDefine, standby_determination, timelineDefine, timelineArrow, timelineClass, timelineConstructSpan, timeline_dropdown_text  } from './timelineLib';


const TimelineModule = (props) => {
 
    const { timeline, contaminant, facility_name, repair } = props;
    const timeline_value_array = Object.keys(timeline);
    const [timelineArrIdx, setTimelineArrIdx] = useState(timeline_value_array[0]);

 
    const onSelectionChange = (e, len, timelineType) => {
        e.preventDefault();
        let find_chart_head = document.getElementsByClassName("timeline-chart-head");
        find_chart_head[0].style.display = "none";
        setTimelineArrIdx(e.target.value);
        for (let i = 0; i < len; i++){
            let current_chart = document.getElementsByClassName("timeline-chart-"+ timelineType + "-" + i);
            for (let j = 0; j < current_chart.length; j++){
                current_chart[j].style.display = "none";
            };
        };
    
    };

    const showChart = (e, idx, timelineType, len) => {
        e.preventDefault();
        for (let i = 0; i < len; i++){
            let current_chart = document.getElementsByClassName("timeline-chart-"+ timelineType + "-" + i);
            let find_chart_head = document.getElementsByClassName("timeline-chart-head");
            for (let j = 0; j < current_chart.length; j++){
                if (i === idx) {
                    current_chart[j].style.display = "";
                    find_chart_head[0].style.display = "";
                }
                else {
                    current_chart[j].style.display = "none";
                };
            };
        };
    };
    return !timeline ? <Spinner /> : (
        <div>    
            <h3 id="timeline-title"> Timeline for { contaminant } Compliance in Facility { facility_name } </h3>
            
            <svg version="2" baseProfile="full" width="90%" height="10%" overflow="visible"   xmlns="https://www.w3.org/TR/SVG2/">

                {/* First Vertical Line  */};
                <line x1="0" y1="47%" x2="0" y2="53%" stroke="black" strokeWidth ="2" /> 
                <line x1="0" y1="7%" x2="0" y2="13%" stroke="black" strokeWidth ="2" />
                <text x="-10%" y="5%" className="small" textAnchor='right' style={{ font: "2px"}}> Potential </text>
                <text x="-10%" y="17.5%" className="small" textAnchor='right' style={{ font: "2px"}}> Repair </text>

                <text x="-10%" y="45%" className="small" textAnchor='right' style={{ font:"2px"}}> Sampling  </text>
                <text x="-10%" y="57.5%" className="small" textAnchor='right' style={{ font:"2px"}}> Timeline </text>

                <g id="timelineTooltip">    
                    <title > Click on time bars to see details </title> 
                    <image href={qm} width="24px" height="24px" x="101%" y="42%" />
                </g>
                {/* regulation and dates*/};
                
                {
                repairDefine(repair[standby_determination[timelineArrIdx]], timeline[timelineArrIdx][0].start_date, timeline[timelineArrIdx][timeline[timelineArrIdx].length - 1].end_date).map((ratio_unit, idx) => (
                    (ratio_unit[3] !== "point") ? (
                        <g key={idx} >
                            <title> { (ratio_unit[2] === "black") ? ("From " + ratio_unit[4] + " to " + ratio_unit[5] + " there is a possible repair.") : ("") } </title> 
                            <line  className="standby-unit" x1= {ratio_unit[0] + "%"} y1= "10%" x2 = {ratio_unit[1] + "%"}  y2= "10%" stroke= {ratio_unit[2]} strokeWidth="5" />
                            <line x1={ratio_unit[1] + "%"} y1="7%" x2={ratio_unit[1] + "%"} y2="13%" stroke="black" strokeWidth ="2" />
                        </g>
                    ) :
                    (
                        <g>
                            <g key={idx} className="repair-unit"  >
                                <title> { (ratio_unit[2] === "black") ? ("From " + ratio_unit[4] + " to " + ratio_unit[5] + " there is a possible repair.") : ("") } </title> 
                                <rect   x={(ratio_unit[0] - 1) + "%"} y="10%" width="2%" height="7%" fill={ratio_unit[2]} stroke="black" strokeWidth ="2" />   
                                <line  x1={(ratio_unit[0] - 0.5) + "%"} y1="17%" x2={ ratio_unit[0] + "%" } y2="22%" stroke="black" strokeWidth ="2" />
                                <line  x1={(ratio_unit[0] )+ "%"} y1="22%" x2={ (ratio_unit[0] + 0.5 )+ "%" } y2="17%" stroke="black" strokeWidth ="2" />
                                
                            </g>
                            <line x1={ratio_unit[1] + "%"} y1="7%" x2={ratio_unit[1] + "%"} y2="13%" stroke="black" strokeWidth ="2" />
                        </g>
                    )       
                ))
                };         
                {

                    timelineDefine(timeline[timelineArrIdx]).map((ratio_unit, idx) => (
                        (ratio_unit[3] !== "point") ? (
                            <g key={idx} onClick={(e) => showChart(e, idx, timelineArrIdx, timelineDefine(timeline[timelineArrIdx]).length)}  tabIndex="0" >
                                <line className='timeline-unit' x1= {ratio_unit[0] + "%"} y1= "50%" x2 = {ratio_unit[1] + "%"}  y2= "50%" stroke= {timeline_color[ratio_unit[2]]} strokeWidth="5" />
                                <line x1={ratio_unit[1] + "%"} y1="47%" x2={ratio_unit[1] + "%"} y2="53%" stroke="black" strokeWidth ="2" />
                            </g>
                        ) :
                        (
                            <g>
                                <g key={idx} className='timeline-unit' onClick={(e) => showChart(e, idx, timelineArrIdx, timelineDefine(timeline[timelineArrIdx]).length)}  tabIndex="0">
                                    <rect  x={(ratio_unit[0] - 1) + "%"} y="35%" width="2%" height="7%" fill={timeline_color[ratio_unit[2]]} stroke="black" strokeWidth ="2" />  
                                    <line  x1={(ratio_unit[0] - 0.5) + "%"} y1="42%" x2={ ratio_unit[0] + "%" } y2="47%" stroke="black" strokeWidth ="2" />
                                    <line  x1={(ratio_unit[0] )+ "%"} y1="47%" x2={ (ratio_unit[0] + 0.5 )+ "%" } y2="42%" stroke="black" strokeWidth ="2" />
                                    
                                </g>
                                <line x1={ratio_unit[1] + "%"} y1="47%" x2={ratio_unit[1] + "%"} y2="53%" stroke="black" strokeWidth ="2" />
                            </g>
                        )       
                    ))
                };

                {/* Timeline arrows */};
                {

                    timelineArrow(timeline[timelineArrIdx]).map((point_unit, idx) => (
                        <g key={idx} className={ timelineClass(idx, timelineArrIdx) } style={{ display: "none" }}> 
                            <line  x1= {point_unit[0] + "%"} y1= "20%" x2 = {point_unit[0] +"%"}  y2= "30%" stroke= "black" strokeWidth="2" />
                            <line  x1= {point_unit[0] + "%"} y1= "20%" x2 = {(point_unit[0] + 0.5) + "%"}  y2= "23%" stroke= "black" strokeWidth="2" />
                            <line  x1= {point_unit[0] + "%"} y1= "20%" x2 = {(point_unit[0] - 0.5) + "%"}  y2= "23%" stroke= "black" strokeWidth="2" />
                            <line  x1= {point_unit[0] + "%"} y1= "30%" x2 = {(point_unit[0] + 0.5) + "%"}  y2= "27%" stroke= "black" strokeWidth="2" />
                            <line  x1= {point_unit[0] + "%"} y1= "30%" x2 = {(point_unit[0] - 0.5) + "%"}  y2= "27%" stroke= "black" strokeWidth="2" />
                        </g>
                    ))
                };
                
                {/* timeline span */};
                {
                    timelineConstructSpan(timeline[timelineArrIdx]).map((span_unit, idx) => (
                        <g key={idx}> 
                            <line x1= {span_unit[1]} y1= "75%" x2 = {span_unit[2]}  y2= "75%" stroke= "black" strokeWidth="5" />
                            <line x1={span_unit[1]} y1="72%" x2={span_unit[1]} y2="78%" stroke="black" strokeWidth ="2" />
                            <text x={span_unit[1]} y="90%" className="small" textAnchor='middle' transform="rotate(0, 100, 50)">{ span_unit[0] }</text>
                        </g>
                    ))
                };

            
            </svg>
            <table  >
                    <tr className="timeline-chart-head" style={{ display: "none" }}>
                        <th > Determination </th>
                        <th > Regulation </th>
                        <th> Start Date </th>
                        <th> End Date </th>
                    </tr>
                {  
                    timeline[timelineArrIdx].map((timeline_unit, idx) => (
                        <tr key={idx} className= { timelineClass(idx, timelineArrIdx) } style={{ display: "none" }} >
                            <td> { timeline_unit.determination } </td>
                            <td> { timeline_unit.regulation } </td>
                            <td> { ExcelDateToJSDate(timeline_unit.start_date) }</td>
                            <td> { ExcelDateToJSDate(timeline_unit.end_date) }</td>
                        </tr>
                            ))
                };
                </table>

                <h6 id="prelim_text"> PRELIMINARY: ALL RESULTS SHOWN ARE BELIEVED TO BE ACCURATE HOWEVER THERE MAY BE UPDATES AS NEW INFORMATION IS INPUT INCLUDING CONFIRMED MECHANICAL DOWNAGE, SPECIAL PERMIT AMENDMENTS, LOCAL VARIANCES AND MONITORING WAIVERS. </h6>
                <img id="leyenda" src={compliance_legend} alt="#" />
                <div>
                    <p> 
                        Timeline Type:  
                        <select name="current_timeline" onChange={ e => onSelectionChange(e, timelineDefine(timeline[timelineArrIdx]).length, timelineArrIdx)} >
                            <option value={timeline_value_array[0]}> {timeline_dropdown_text[timeline_value_array[0]] + " (Scoring)"} </option>
                            <option value={timeline_value_array[1]}> {timeline_dropdown_text[timeline_value_array[1]]} </option>
                            <option value={timeline_value_array[2]}> {timeline_dropdown_text[timeline_value_array[2]]} </option>
                        </select>
                    </p>
                </div>    
        </div>

    );
        
    


    
}

// const mapStateToProps = state => ({
//     timeline: state.timeline, 
//     potentialrepair: state.potentialrepair
// })


export default connect(null)(TimelineModule);
