// Score Board codes go here
import React, {Fragment, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getWaterSystemByWSNum, getAssociatedFacility, clearFacility, clearWaterSystem } from '../../actions/score'
import { clearTimeline } from '../../actions/timeline';
import { removeAlert } from '../../actions/alert';

// Water System Entry
// Display Water System Score and their Contams
// Display a single Water System Score with one Contam and scores for their facilities
// clicking the facility will bring out the timeline in a pop up

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const ScoreBoardForm = ({ getWaterSystemByWSNum }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        water_system_number: ""
    });
    let display;
    const { water_system_number } = formData ; 

    useEffect(() => {
        Promise.resolve(dispatch(clearWaterSystem())).then(
            () => dispatch(clearFacility())).then(
                () => dispatch(clearTimeline())).then(
                    () => dispatch(removeAlert()))
    }, [dispatch]);

    const onChange = e => {
        e.preventDefault();
        if(isNumeric(e.target.value.substring(0,e.target.value.length)) === false) {
            e.target.value = e.target.value.substring(0,e.target.value.length-1);
        } 
        if(e.target.value.length > 7){
            e.target.value = e.target.value.substring(0,7)
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = e => {
        e.preventDefault();
        getWaterSystemByWSNum(formData).then(
                () => navigate('/watersystemscore'));
    };

    
    display = (
        <Fragment>
            <div className = "container">
            <section className="wrapper style1">

                <h1 className="large text-primary"> Percentile Scores for Water System </h1>
                <div className="col-4 col-12-mobilep">
                    <form className="form-inline" onSubmit={e => onSubmit(e)} >
                        <div className="form-group">
                            <label className="ws_label" htmlFor="ws_input">
                                CA
                                <input 
                                    id="ws_input"
                                    type="text" 
                                    size = "sm"
                                    placeholder="Water System Number" 
                                    name="water_system_number" 
                                    onChange={ e => onChange(e) }
                                    value={ water_system_number}  
                                />
                            </label>
                        </div>
                        <div className="col-4 col-12-mobilep">
                            <input type="submit" className="btn btn-dark my-1" value="Submit" />
                        </div>
                    </form>
                </div>
                </section>

            </div>
        </Fragment>
    );
    
    return (
        <Fragment> 
            <section className="wrapper style1">
                {display}
            </section>
        </Fragment>
    );
};

ScoreBoardForm.propTypes = {
    getWaterSystemByWSNum: PropTypes.func.isRequired,
    getAssociatedFacility: PropTypes.func.isRequired
};

export default connect(null, {getWaterSystemByWSNum, getAssociatedFacility})(ScoreBoardForm);