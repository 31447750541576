import api from '../utils/api';
import { setAlert } from './alert';
import {
    GET_ALL_ASSOCIATED_FACILITY,
    GET_ALL_WATERSYSTEM_CONTAM_SCORE,
    GET_WATERSYSTEM_AND_FAC,
    CLEAR_WATERSYSTEM,
    CLEAR_FACILITY,
    GET_ALL_WATERSYSTEM_CONTAM_SCORE_ERROR,
    GET_ALL_ASSOCIATED_FACILITY_ERROR,
    GET_WATERSYSTEM_AND_FAC_ERROR,

} from './types';



// GET POSTS

export const getLoggedInWaterSystemByWSNum = water_system_number => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {

        const res = await api.get(`/watersystemscore/search_by_number/${water_system_number}`, config);
        dispatch({
            type: GET_ALL_WATERSYSTEM_CONTAM_SCORE,
            payload: res.data
        }, 3000);

    } catch (err) {
        dispatch({
            type: GET_ALL_WATERSYSTEM_CONTAM_SCORE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        }, 3000);
        dispatch(setAlert('Could Not Load Water System Score. If problem persists contact webmaster.'));
    }
}

export const getLoggedInAssociatedFacility = water_system_number => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await api.get(`/facility/get_by_ws_and_fac/${water_system_number}`, config);
        dispatch({
            type: GET_WATERSYSTEM_AND_FAC,
            payload: res.data
        }, 60000);

    } catch (err) {
        dispatch({
            type: GET_WATERSYSTEM_AND_FAC_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        }, 60000);
        dispatch(setAlert('Could Not Load Water System Score. If problem persists contact webmaster.'));
    }
}

export const getWaterSystemByWSNum = formData => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        if(formData.water_system_number.substring(0,2) !== "CA"){
            formData.water_system_number = "CA" + formData.water_system_number
        }
        const res = await api.post(`/watersystemscore/search_by_number`, formData, config);
        dispatch({
            type: GET_ALL_WATERSYSTEM_CONTAM_SCORE,
            payload: res.data
        }, 3000);

    } catch (err) {
        dispatch({
            type: GET_ALL_WATERSYSTEM_CONTAM_SCORE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        }, 3000);
        dispatch(setAlert('Could Not Load Water System Score. If problem persists contact webmaster.'));
    }
}

export const getAssociatedFacility = formData => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {

        if(formData.water_system_number.substring(0,2) !== "CA"){
            formData.water_system_number = "CA" + formData.water_system_number
        }        

        const res = await api.post(`/facility/get_by_ws_and_fac`, formData, config);
        dispatch({
            type: GET_WATERSYSTEM_AND_FAC,
            payload: res.data
        }, 60000);

    } catch (err) {
        dispatch({
            type: GET_WATERSYSTEM_AND_FAC_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        }, 60000);
        dispatch(setAlert('Could Not Load Water System Score. If problem persists contact webmaster.'));
    }
}


export const getAllAssociatedFacility = (ws_id, contam_id, isAuth) => async dispatch => {
    try {

        let isLoggedIn; 
        if (isAuth){        
            isLoggedIn = await api.get(`/facilityscore/get_by_ws/${ws_id}/contam/${contam_id}/`);
        }
        else {
            isLoggedIn = await api.get(`/facilityscore/get_by_ws/${ws_id}/contam/${contam_id}/trial`);
        }
        const res = isLoggedIn
        dispatch({
            type: GET_ALL_ASSOCIATED_FACILITY,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ALL_ASSOCIATED_FACILITY_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
        dispatch(setAlert('Could Not Load Facilities. If problem persists contact webmaster.'));
    }
}



export const clearWaterSystem = () => ({ type: CLEAR_WATERSYSTEM });
export const clearFacility = () => ({ type: CLEAR_FACILITY });