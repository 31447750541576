import React,{ Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layouts/Spinner';
import Score from './Score';
import  {getAssociatedFacility} from '../../actions/score';
import { removeAlert } from '../../actions/alert';



const WaterSystemFacility = ({ score: { watersystem, loading }, getAssociatedFacility, history }) => {
    useEffect(() => {
        removeAlert();
        window.scrollTo(0, 0);
      }, []);
    return (
        <Fragment> 
            <div className = "container">
                
            { loading ? (<Spinner />) :( 
                <section className="wrapper style1">
                    {/* <h2>Water System Score for { watersystem.water_system.water_system_name }</h2> */}
                        <div id="watersystemcard">
                            <ul>
                                <li className="water_system_card_content">{ watersystem.water_system.water_system_number }</li>
                                <li className="water_system_card_content">{ watersystem.water_system.water_system_name }</li>
                                <li className="water_system_card_content"> Percentile Average: { watersystem.water_system_score.ave_score_red_lean_percentile }</li>
                                {/* <li className="water_system_card_content"> Yellow Lean Percentile Average: { watersystem.water_system_score.yellow_lean_percentile_average }</li> */}
                            </ul>
                        </div>
                        <h2> Contaminant Scores for the System </h2>
                        <div id="contam_score_cards">   
                            {
                                watersystem.new_ws_contam.map((ws_contam, idx) => (

                                    <div key={idx} className = "button contam">
                                        <Score history={history} ws_id={ws_contam.ws_id} contam_id={ws_contam.contam_id} group_const_id={ws_contam.group_const_id} red_lean_percentile_average={ws_contam.ave_score_red_lean_percentile} />
                                    </div>
                                ))
                            }
                        </div>
                </section>
                )}
            </div>  
        </Fragment> 
    );
};

WaterSystemFacility.propTypes = {
    score: PropTypes.object, 
    getAssociatedFacility: PropTypes.func
};

const mapStateToProps = state => ({
    score: state.score
});

export default connect(mapStateToProps, {getAssociatedFacility})(WaterSystemFacility);