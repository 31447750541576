import React,{ Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux';
import Spinner from '../layouts/Spinner';
import Score from './Score'
import  {getAllAssociatedFacility, clearFacility} from '../../actions/score';
import { clearTimeline } from '../../actions/timeline';
import { removeAlert } from '../../actions/alert';


const WaterSystemScoreBoard = ({ score: { watersystem: {water_system, water_system_score, new_ws_contam}, loading, error: {status} }, history, auth: { user } }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        removeAlert()
        Promise.resolve(dispatch(clearFacility())).then(
                () => dispatch(clearTimeline()))
        window.scrollTo(0, 0)
      }, [dispatch])
    return (
        <Fragment> 
            <div className = "container">
                
            { (loading)? (<Spinner />) :( 
                <section className="wrapper style1">
                    <h2>Water System Score for { water_system.water_system_name }</h2>
                        
                        <div id="watersystemcard">
                            <ul>
                                <li className="water_system_card_content">{ water_system.water_system_number }</li>
                                <li className="water_system_card_content">{ water_system.water_system_name }</li>
                                { water_system_score ? (<li className="water_system_card_content"> Percentile Average: { water_system_score.ave_score_red_lean_percentile }</li>) : (<Spinner />) }
                                {/* <li className="water_system_card_content"> Yellow Lean Percentile Average: { watersystem.water_system_score.yellow_lean_percentile_average }</li> */}
                            </ul>
                        </div>
                        <h2> Contaminant Scores for the System </h2>
                        <div id="contam_score_cards">   
                            {
                                new_ws_contam.map((ws_contam, idx) => (

                                    <div key={idx} className = "button contam">
                                        <Score history={history} ws_id={ws_contam.ws_id} contam_id={ws_contam.contam_id} contam_name={ws_contam.contam_name} red_lean_percentile_average={ws_contam.red_lean_percentile}/>
                                    </div>
                                ))
                            }
                        </div>
                </section>
                )};
            </div>

                    
        </Fragment> 
    );
};

WaterSystemScoreBoard.propTypes = {
    score: PropTypes.object, 
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    score: state.score,
    auth: state.profile
});

export default connect(mapStateToProps, {getAllAssociatedFacility})(WaterSystemScoreBoard);