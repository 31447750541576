import api from '../utils/api';
import { setAlert } from './alert';
import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT
} from './types';
// LOAD USER

export const loadUser = () => async (dispatch) => {
    try {
      const res = await api.get('/auth');

      // }
      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
          errors.forEach((err) => dispatch(setAlert(err.msg, 'danger')));
      }
      
      dispatch({
        type: AUTH_ERROR
      });
    }
  };

// Login User
export const login = (user_name, password) => async dispatch => {
    const body = { user_name, password };

    try {
        const res = await api.post('/auth', body);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(loadUser());

    }  catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((err) => dispatch(setAlert(err.msg, 'danger')));
        }
        
        dispatch({
            type: LOGIN_FAIL
        });
    }
};

// LOGOUT /CLEAR PROFILE

export const logout = () => ({ type: LOGOUT });
