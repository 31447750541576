import { combineReducers } from "redux";
import alert from './alert';
import auth from './auth';
import profile from './profile';
import score from './score';
import timeline from './timeline';
import potentialrepair from './potentialrepair';
import method from './method';


export default combineReducers({
    alert,
    auth,
    profile,
    score,
    timeline,
    potentialrepair,
    method
});