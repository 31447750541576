import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { removeAlert } from '../../actions/alert';
const AboutUs = () => {
    useEffect(() => {
        removeAlert()
        window.scrollTo(0, 0)
    }, []);
    return (
        <Fragment>
            <section className="container"> 
                <div id="header" className= "wrapper style-1">
                    <h1>About Vapyr Analytics</h1>
                    <p className='article'>
                        Vapyr Analtyics was started in 2021 by Sarmad Moalem and Jae Hong with a focus on building new tools and platforms for the environmental and medical industries. 
                        We specialize in providing end-to-end data platform development as well as custom data analysis to investigate new solutions for our clients.
                    </p>
                    <p className='article'>
                        Our work includes regulatory sampling analysis that ultimately lead to our development of a unified platform that handles all Title 22 drinking water sampling review and we are currently doing patient outcome studies that better evaluate the performance of medical providers during the COVID-19 pandemic.
                        Both of these projects are large leaps over industry standard practices and software that were failing to produce a complete or accurate picture as conditions and quantity of data increased.
                    </p>
                </div>
                {/* <Footer /> */}

            </section>

        </Fragment>
        
        
    );
};

AboutUs.propTypes = {

}

export default connect(null)(AboutUs);
