import api from '../utils/api';
import { setAlert } from './alert';
import {
    CLEAR_POTENTIAL_REPAIR,
    GET_POTENTIAL_REPAIR, 
    REPAIR_ERROR,
    TRIAL_REPAIR_ERROR
} from './types';

// GET POSTS


export const getPotentialRepair = fac_id => async dispatch => {
    try {
        const res = await api.get(`/potentialrepair/${fac_id}`);

        dispatch({
            type: GET_POTENTIAL_REPAIR,
            payload: res.data
        }, 6000);

    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((err) => dispatch(setAlert(err.msg, 'danger')));
        }
        dispatch({
            type: REPAIR_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        }, 6000)
    }
}

export const getPotentialRepairTrial = fac_id => async dispatch => {
    try {
        const res = await api.get(`/potentialrepair/${fac_id}/trial`);

        dispatch({
            type: GET_POTENTIAL_REPAIR,
            payload: res.data
        }, 6000);

    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((err) => dispatch(setAlert(err.msg, 'danger')));
        }
        dispatch({
            type: TRIAL_REPAIR_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        }, 6000)
    }
}

export const clearPotentialRepair = () => ({ type: CLEAR_POTENTIAL_REPAIR });
