import React, { useState, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { getAllTimeline, getAllTrialTimeline } from '../../actions/timeline';
import { getPotentialRepair, getPotentialRepairTrial } from '../../actions/potentialrepair';
import PropTypes from 'prop-types';
import TimelineModule from './TimelineModule';
import Spinner from '../layouts/Spinner';
import TableauReport from 'tableau-react';
import { ExcelDateToJSDate, timeline_color, repairDefine, standby_determination, timelineDefine, timelineArrow, timelineClass, timelineConstructSpan, timeline_dropdown_text  } from './timelineLib';


const options =  {
    // height: 100,
    // width: 100,
    hideTabs: false,
    // added interval support - an integer can be passed as milliseconds in the options object and refreshDataAsync() will refresh the data automatically on your preferred interval.
    // All other vizCreate options are supported here, too
    // They are listed here: https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record
  };
  
  const filters = {
    Colors: ['Blue', 'Red'],
    Sizes: ['Small', 'Medium']
  };
  
  const parameters = {
    contam_id_param: 287,
    fac_id_param: 129,
    Table: "Effective"
  };
  
  const TableauTimelineModule = (props) => {
    const { timeline, contaminant, facility_name, repair } = props;
    const timeline_value_array = Object.keys(timeline);
    const [timelineArrIdx, setTimelineArrIdx] = useState(timeline_value_array[0]);

    const onSelectionChange = (e, len, timelineType) => {
      e.preventDefault();
      let find_chart_head = document.getElementsByClassName("timeline-chart-head");
      find_chart_head[0].style.display = "none";
      setTimelineArrIdx(e.target.value);
      for (let i = 0; i < len; i++){
          let current_chart = document.getElementsByClassName("timeline-chart-"+ timelineType + "-" + i);
          for (let j = 0; j < current_chart.length; j++){
              current_chart[j].style.display = "none";
          };
      };
    
    };
    return (
      <div>
        <TableauReport
          url="https://public.tableau.com/shared/SDC998DXP?:display_count=n&:origin=viz_share_link"
          filters={filters}
          parameters={parameters}
          options={options} // vizCreate options
          // Overwrite default query params
          // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
          query="?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes"
        />
        <div>
          <p> 
              Timeline Type:  
              <select name="current_timeline" onChange={ e => onSelectionChange(e, timelineDefine(timeline[timelineArrIdx]).length, timelineArrIdx)} >
                  <option value={timeline_value_array[0]}> {timeline_dropdown_text[timeline_value_array[0]] + " (Scoring)"} </option>
                  <option value={timeline_value_array[1]}> {timeline_dropdown_text[timeline_value_array[1]]} </option>
                  <option value={timeline_value_array[2]}> {timeline_dropdown_text[timeline_value_array[2]]} </option>
              </select>
          </p>
      </div>   
    </div>
    )
  };
  



export default connect(null)(TableauTimelineModule);
