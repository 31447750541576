import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { getAllTimeline, getAllTrialTimeline } from '../../actions/timeline';
import { getPotentialRepair, getPotentialRepairTrial } from '../../actions/potentialrepair';
import PropTypes from 'prop-types';
import TimelineModule from './TimelineModule';
import Spinner from '../layouts/Spinner';
import TableauTimelineModule from './TableauTimelineModule';
import TimelineModuleRevised from './TimelineModuleRevised';


const Timeline = (props) => {
    const { watersystem, facility, facility_contam, timeline, potentialrepair, conv_score, auth: { isAuthenticated }} = props;
    let conv_score_num = parseFloat(conv_score);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    let timeline_arr = []
    let timeline_url = ''
    if (conv_score > 600) {
        timeline_arr = ["Effective", "Cycle", "Period"]
    }
    else if (conv_score <= 300 ){
        timeline_arr = ["Period", "Effective", "Cycle"]
    }
    else {
        timeline_arr = ["Cycle", "Effective", "Period"]
    }
    if(isAuthenticated){
        // Promise.resolve(dispatch(getAllTimeline(facility_contam.fac_id, facility_contam.contam_id, conv_score_num)))
        timeline_url = "https://public.tableau.com/views/vapyr_analytics_user_timeline/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link"
    }
    else {
        // Promise.resolve(dispatch(getAllTrialTimeline(facility_contam.fac_id, facility_contam.contam_id, conv_score_num)));
        timeline_url = "https://public.tableau.com/views/demo_timelines_revised/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link"
    }
    
    function handleClick(e) {
        e.preventDefault();
        // if(isAuthenticated){
        //     Promise.resolve(dispatch(getPotentialRepair(parseInt(facility_contam.fac_id)))).then(
        //         () => dispatch(getAllTimeline(facility_contam.fac_id, facility_contam.contam_id, conv_score_num)));
        // }
        // else {
        //     Promise.resolve(dispatch(getPotentialRepairTrial(parseInt(facility_contam.fac_id)))).then(
        //         () => dispatch(getAllTrialTimeline(facility_contam.fac_id, facility_contam.contam_id, conv_score_num)));
        // }
      
      
                        
        setShow(true);
        
    };
    const handleClose = () => {
        setShow(false);

    };
    return (
        
        <div className="button contam">
            {/* { show  ? <div className="timeline">
                <div className="timeline-inner">
                    { timeline.loading ? <Spinner /> : 
                        <TimelineModule loading = {timeline.loading} timeline={timeline.timeline} contaminant={facility.contam_name} facility_contam={facility_contam} facility_name={facility_contam.facility_name} repair={potentialrepair.standby} conv_score={conv_score_num}></TimelineModule>  
                    }
                    <button className="close-btn" onClick={handleClose}>Close Timeline</button>
                </div>
            </div> : null} */}
            { show  ? <div className="timeline">
                <div className="timeline-inner">
                    <TimelineModuleRevised loading = {timeline.loading} timeline_arr= {timeline_arr} timeline_url = {timeline_url} contaminant={facility.contam_name} facility_contam={facility_contam} facility_name={facility_contam.facility_name} contam_id= { facility_contam.contam_id } fac_id= { facility_contam.fac_id }/>  
                    <button className="close-btn" onClick={handleClose}>Close Timeline</button>
                </div>
            </div> : null}
            
            <div key={facility_contam._id}>
                <p>{watersystem.water_system.water_system_number}- { facility_contam.fac_state_assgn_id } </p>
                <p> {facility.contam_name} for {facility_contam.facility_name } </p>
                <p> Percentile: {facility_contam.red_lean_percentile }</p>
            </div>
            <button onClick={handleClick}>Show Timeline</button>
        </div>
    );
};

Timeline.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    timeline: state.timeline, 
    potentialrepair: state.potentialrepair,
    auth: state.auth
});


export default connect(mapStateToProps, {getAllTimeline, getAllTrialTimeline, getPotentialRepair, getPotentialRepairTrial})(Timeline);
