import {
    GET_POTENTIAL_REPAIR,
    GET_POTENTIAL_REPAIR_TRIAL,
    CLEAR_POTENTIAL_REPAIR,
    TRIAL_REPAIR_ERROR,
    REPAIR_ERROR
} from '../actions/types';

const initialState = {
    standby: [],
    loading: true,
    error: {}
};

function postPotentialRepair(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_POTENTIAL_REPAIR:
        case GET_POTENTIAL_REPAIR_TRIAL:
            return {
                ...state,
                standby: payload,
                loading: false
            };
        case CLEAR_POTENTIAL_REPAIR:
            return {
                ...state,
                standby: [],
                loading: true,
                error: {}
            };
        case TRIAL_REPAIR_ERROR:
        case REPAIR_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
                
            };
        default:
            return state;
    };
};

export default postPotentialRepair;