import { useNavigate } from "react-router-dom";

const Score = props => {
    let navigate = useNavigate();
    const { ws_id, contam_id, contam_name, red_lean_percentile_average } = props;
    const handleClick = (e) => {
        e.preventDefault();        
        navigate(`/facilitysystemscore/${ws_id}/${contam_id}`);
    };

    return (
        <div onClick={e => handleClick(e)}>
            <p>{ contam_name }</p>
            <p> Percentile Average: { red_lean_percentile_average }</p>
        </div>
    );
};

export default Score;